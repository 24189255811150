*:focus { outline: none; }

body {
  margin: 0;
  font-family: 'Architects Daughter', cursive;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5fcff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-size: 2rem;
  margin: 60px;
}

.header {
  padding: 15px;
  text-align: center;
  background: #007399;
  color: #f5fcff;
  font-size: 30px;
}

.row {
  display: flex;
  flex-direction: row;
}

.game-state, .instructions {
  flex-grow: 1;display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.grid {
  text-align: center;
  width: 600px;
  height: 600px;
}

.square {
  margin: 3px;
  border: 1px solid black;
  border-radius: 8px;
}

.play-button {
  font-family: inherit;
  font-size: 6rem;
  border-radius: 20px;
  border: 4px solid black;
  width: 100%;
  height: 100%;
}

.big {
  font-size: 5rem;
}
